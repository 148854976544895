<template>
    <div>
        <app-table
            :columns="columns"
            :actions="tableActions"
            url="contacts"
            ref="table"
            @ajaxSuccess="handleTableAjaxSuccess"
        >
            <template v-slot:actions>
                <v-btn 
                    small 
                    color="primary" 
                    dark 
                    class="mr-2"
                    :to="{ name: 'customer.contacts.create' }"
                >
                    <v-icon left dense>fal fa-plus</v-icon> Add
                </v-btn>
            </template>
            <template v-slot:item.sites="{ item }">
                {{ item.sites.map((item) => item.title).join(', ') }}
            </template>
            <template v-slot:item.roles="{ item }">
                {{ item.roles.map((role) => role.title).join(', ') }}
            </template>
            <template v-slot:details="{ details }">
                <v-card-title>{{ details.name }}</v-card-title>
                <v-card-subtitle>{{ details.email }}</v-card-subtitle>
                <v-simple-table>
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <td>Status</td>
                                <td>{{ details.status }}</td>
                            </tr>
                            <tr>
                                <td>Username</td>
                                <td>{{ details.username }}</td>
                            </tr>
                            <tr>
                                <td>Mobile</td>
                                <td>{{ details.mobile_phone || '--' }}</td>
                            </tr>
                            <tr>
                                <td>Work Phone</td>
                                <td>{{ details.work_phone || '--' }}</td>
                            </tr>
                            <tr>
                                <td>Home Phone</td>
                                <td>{{ details.home_phone || '--' }}</td>
                            </tr>
                            <tr>
                                <td>Fax</td>
                                <td>{{ details.fax }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-card-title>Sites</v-card-title>
                <v-simple-table>
                    <template v-slot:default>
                        <tbody>
                            <tr v-for="site in details.sites" :key="site.id">
                                <td>{{ site.title }}</td>
                                <td>{{ site.address.complete_address_for_human }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>            
        </app-table>
        <add-modal @success="$refs.table.init()" />
        <edit-modal @success="$refs.table.init()" />
    </div>
</template>

<script>
    import AppTable from '@/components/Table.vue';
    import Http from '@/utils/httpClient';
    import AddModal from './modals/Add.vue';
    import EditModal from './modals/Edit.vue';

    export default {
        components: {
            AppTable,
            AddModal,
            EditModal,
        },
        data() {
            return {
                sites: [],
                roles: [],
                statuses: [],
                columns: [                    
                    {
                        text: 'Name',
                        value: 'name',
                        sortable: false,
                        width: 150,
                    },
                    {
                        text: 'Email',
                        value: 'email',
                        sortable: false,
                        width: 150,
                    },
                    {
                        text: 'Roles',
                        value: 'roles',
                        slotName: 'item.roles',
                        sortable: false,
                        width: 150,
                    },
                    {
                        text: 'Sites',
                        value: 'sites',
                        slotName: 'item.sites',
                        sortable: false,
                        width: 150,
                    },
                    {
                        text: 'Status',
                        value: 'status_for_human',
                        sortable: false,
                        width: 70,
                    },
                ],
                tableActions: [
                    {
                        label: 'Edit',
                        icon: 'fal fa-pencil fa-fw',
                        handler: (contact) => {
                            this.$router.push({
                                name: 'customer.contacts.edit',
                                params: {
                                    contactId: contact.id,
                                }
                            })
                        }
                    },                 
                    {
                        label: 'Delete',
                        icon: 'fal fa-trash-alt fa-fw',
                        handler: (item) => {
                            this.$root.$confirm({
                                title: 'Are you sure?',
                                message: `You are about to delete ${item.name} permanently.`,
                                agreeButtonText: 'Yes, delete it',
                                agreeButtonColor: 'error',
                            })
                            .then(() => {
                                this.$root.$loading.open({ text: `Deleting ${item.name} permanently...` });
                                Http.delete(`contacts/${item.id}`)
                                    .then(() => {
                                        this.$root.$loading.close();
                                        this.$root.$snackbar.open({
                                            text: `${item.name} was successfully deleted.`,
                                            type: 'success',
                                        });
                                        this.$refs.table.init();
                                    })
                                    .catch(() => this.$root.$loading.close());
                            })
                            .catch(() => {
                                this.$root.$snackbar.open({
                                    text: 'Action cancelled',
                                    type: 'info',
                                });
                            })
                        }
                    }
                ],
            }
        },
        methods: {
            handleTableAjaxSuccess(data) {
                this.sites = data.sites;
                this.roles = data.roles;
                this.statuses = data.statuses;
            }
        }
    }
</script>
